import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
 // { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },

   { path: '', redirectTo: '/side-menu/shopping/tabs/tab1', pathMatch: 'full' },

  { path: 'side-menu', loadChildren: './side-menu/side-menu.module#SideMenuPageModule' },
  



  //**************************************//
  //********** Authentication ************//
  //**************************************//

  


  //*************************************//
  //********** Shopping app ************//
  //************************************//


  { path: 'shopping-group', loadChildren: './app-shopping/shopping-group/shopping-group.module#ShoppingGroupPageModule' },
  { path: 'shopping-cart', loadChildren: './app-shopping/shopping-cart/shopping-cart.module#ShoppingCartPageModule' },
  
 
  { path: 'shopping-address', loadChildren: './app-shopping/shopping-address/shopping-address.module#ShoppingAddressPageModule' },
  { path: 'shopping-address-add', loadChildren: './app-shopping/shopping-address-add/shopping-address-add.module#ShoppingAddressAddPageModule' },
  { path: 'shopping-address-edit/:addressId', loadChildren: './app-shopping/shopping-address-edit/shopping-address-edit.module#ShoppingAddressEditPageModule' },
  { path: 'shopping-detail/:itemId', loadChildren: './app-shopping/shopping-detail/shopping-detail.module#ShoppingDetailPageModule' },
  { path: 'shopping-item/:categoryId/:name', loadChildren: './app-shopping/shopping-item/shopping-item.module#ShoppingItemPageModule' },
  
  
  { path: 'shopping-payment/:addressId', loadChildren: './app-shopping/shopping-payment/shopping-payment.module#ShoppingPaymentPageModule' },
   /// ****** check this line ******//
  { path: 'shopping-summary/:addressId/:paymentType', loadChildren: './app-shopping/shopping-summary/shopping-summary.module#ShoppingSummaryPageModule' },
  { path: 'shopping-finish', loadChildren: './app-shopping/shopping-finish/shopping-finish.module#ShoppingFinishPageModule' },
 
  { path: 'shopping-profile', loadChildren: './app-shopping/shopping-profile/shopping-profile.module#ShoppingProfilePageModule' },

 // { path: 'shopping-review/:itemId', loadChildren: './app-shopping/shopping-review/shopping-review.module#ShoppingReviewPageModule' },
 // { path: 'shopping-review-add/:itemId', loadChildren: './app-shopping/shopping-review-add/shopping-review-add.module#ShoppingReviewAddPageModule' },
 // { path: 'shopping-image-gallery', loadChildren: './app-shopping/shopping-image-gallery/shopping-image-gallery.module#ShoppingImageGalleryPageModule' },

  

  //**************************//
  //******* Ui layout ********//
  //**************************//
  { path: 'gallery', loadChildren: './ui-layouts/gallery/gallery.module#GalleryPageModule' },
  { path: 'gallery-viewer-modal', loadChildren: './ui-layouts/gallery-viewer-modal/gallery-viewer-modal.module#GalleryViewerModalPageModule' },
  { path: 'masonry', loadChildren: './ui-layouts/masonry/masonry.module#MasonryPageModule' },
  { path: 'feed', loadChildren: './ui-layouts/feed/feed.module#FeedPageModule' },
  { path: 'timeline', loadChildren: './ui-layouts/timeline/timeline.module#TimelinePageModule' },
  { path: 'header-hide-title', loadChildren: './ui-components/header-hide-title/header-hide-title.module#HeaderHideTitlePageModule' },
  { path: 'skeleton', loadChildren: './ui-components/skeleton/skeleton.module#SkeletonPageModule' },
  { path: 'shopping-my-order-detail', loadChildren: './app-shopping/shopping-my-order-detail/shopping-my-order-detail.module#ShoppingMyOrderDetailPageModule' },
  
  { path: 'header-fading', loadChildren: './ui-components/header-fading/header-fading.module#HeaderFadingPageModule' },
  { path: 'header-transparent', loadChildren: './ui-components/header-transparent/header-transparent.module#HeaderTransparentPageModule' },
  { path: 'header-shrinking', loadChildren: './ui-components/header-shrinking/header-shrinking.module#HeaderShrinkingPageModule' },
  { path: 'animation', loadChildren: './ui-components/animation/animation.module#AnimationPageModule' },
  { path: 'rating', loadChildren: './ui-components/rating/rating.module#RatingPageModule' },
  { path: 'header-footer-shrinking', loadChildren: './ui-components/header-footer-shrinking/header-footer-shrinking.module#HeaderFooterShrinkingPageModule' },
  { path: 'footer-shrinking', loadChildren: './ui-components/footer-shrinking/footer-shrinking.module#FooterShrinkingPageModule' },
  { path: 'modal', loadChildren: './ui-components/modal/modal.module#ModalPageModule' },
  { path: 'modal-detail', loadChildren: './ui-components/modal-detail/modal-detail.module#ModalDetailPageModule' },




  //*************************************//
  //********** Ui components ************//
  //*************************************//
  // { path: 'accordion', loadChildren: './ui/accordion/accordion.module#AccordionPageModule' },
  // { path: 'card', loadChildren: './ui/card/card.module#CardPageModule' },
  // { path: 'list', loadChildren: './ui/list/list.module#ListPageModule' },
  // { path: 'horizontal', loadChildren: './ui-components/horizontal/horizontal.module#HorizontalPageModule' },
  // { path: 'fading-header', loadChildren: './ui-components/fading-header/fading-header.module#FadingHeaderPageModule' },
  // { path: 'transparent-header', loadChildren: './ui-components/transparent-header/transparent-header.module#TransparentHeaderPageModule' },
  // { path: 'animation', loadChildren: './ui/animation/animation.module#AnimationPageModule' },

 
  // { path: 'all', loadChildren: './ui-components/all/all.module#AllPageModule' },
  // { path: 'header-transparent', loadChildren: './ui/header-transparent/header-transparent.module#HeaderTransparentPageModule' },
  // { path: 'header-shrinking', loadChildren: './ui/header-shrinking/header-shrinking.module#HeaderShrinkingPageModule' },
  // { path: 'header-fading', loadChildren: './ui/header-fading/header-fading.module#HeaderFadingPageModule' },
  // { path: 'actionsheet', loadChildren: './ui/actionsheet/actionsheet.module#ActionsheetPageModule' },
  // { path: 'alert', loadChildren: './ui/alert/alert.module#AlertPageModule' },
  // { path: 'button', loadChildren: './ui/button/button.module#ButtonPageModule' },
  // { path: 'badge', loadChildren: './ui/badge/badge.module#BadgePageModule' },
  // { path: 'fab', loadChildren: './ui/fab/fab.module#FabPageModule' },
  // { path: 'datetime', loadChildren: './ui/datetime/datetime.module#DatetimePageModule' },
  // { path: 'grid', loadChildren: './ui/grid/grid.module#GridPageModule' },
  // { path: 'segment', loadChildren: './ui/segment/segment.module#SegmentPageModule' },
  // { path: 'horizontal', loadChildren: './ui/horizontal/horizontal.module#HorizontalPageModule' },
  // { path: 'rating', loadChildren: './ui/rating/rating.module#RatingPageModule' },
  // { path: 'toast', loadChildren: './ui/toast/toast.module#ToastPageModule' },
  // { path: 'modal', loadChildren: './ui/modal/modal.module#ModalPageModule' },

  // { path: 'ionic', loadChildren: './ionic/ionic.module#IonicPageModule' },
  // { path: 'profile1', loadChildren: './ui-layouts/profile1/profile1.module#Profile1PageModule' },
  
  // { path: 'profile2', loadChildren: './ui-layouts/profile2/profile2.module#Profile2PageModule' },
  // { path: 'profile3', loadChildren: './ui-layouts/profile3/profile3.module#Profile3PageModule' },
  // { path: 'profile4', loadChildren: './ui-layouts/profile4/profile4.module#Profile4PageModule' },
  // { path: 'chat', loadChildren: './ui-layouts/chat/chat.module#ChatPageModule' },

  //{ path: 'ionic-component', loadChildren: './services/ionic-component/ionic-component.module#IonicComponentPageModule' },

  // { path: 'travel-tag', loadChildren: './app-travel/travel-tag/travel-tag.module#TravelTagPageModule' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
