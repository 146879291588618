import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActivatedRoute , Router} from '@angular/router';
import { OfferteService } from './services/offerte.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ShoppingDetailPage } from './app-shopping/shopping-detail/shopping-detail.page';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    public offerteService: OfferteService,
    private navController: NavController,
    private deeplinks: Deeplinks
  ) {
    this.initializeApp();
  }

showed = false;

  
  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      (<any>window).admob.banner.show({
        id: {
          // replace with your ad unit IDs
          android: 'ca-app-pub-8801329898662875/3444398096',
          ios: 'ca-app-pub-8801329898662875/6745348850',
        },
      });

      this.platform.resume.subscribe(() => {      
          console.log('**** RESUMED****');

          if(!this.showed){
            this.showed = true;
            (<any>window).admob.interstitial.load({
              id: {
                // replace with your ad unit IDs
                android: 'ca-app-pub-8801329898662875/3664793904',
                ios: 'ca-app-pub-8801329898662875/2351712235',
              },
            }).then(() => (<any>window).admob.interstitial.show())
          }else{
            this.showed = false;
          }
          

      });

      /*
      this.deeplinks.routeWithNavController(this.navController, {
        '/shopping-detail/:itemId': ShoppingDetailPage
      }).subscribe((match) => {
        // match.$route - the route we matched, which is the matched entry from the arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        console.log('Successfully matched route', match);

        if(match.$link.queryString.indexOf("item") > -1){

          var id = match.$link.queryString.split("item=")[1];

          this.offerteService.getItem(id).then(res => {
            console.log("item")
            console.log(res)
              this.offerteService.item = res;
              this.offerteService.getSingolaOfferta(id).then(resp => {
                debugger
                console.log("singola offerta")
                console.log(resp)
                this.offerteService.currentObj = resp[0];
                setTimeout(() => {
                  console.log("goo")
                  this.router.navigateByUrl('shopping-detail/'+id);
                },500)           
              }).catch(err => {
                console.error(err)
              });
          }).catch(err => {
            console.error(err)
          });
        }


      },
      (nomatch) => {
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match', nomatch);
      });





      (<any>window).FirebasePlugin.onMessageReceived((message) => {
          console.log(message)
          console.log("NOTIFICA")
          if(message.idItem && message.tap){
            this.offerteService.getItem(message.idItem).then(res => {
              console.log("item")
              console.log(res)
                this.offerteService.item = res;
                this.offerteService.getSingolaOfferta(message.idItem).then(resp => {
                  debugger
                  console.log("singola offerta")
                  console.log(resp)
                  this.offerteService.currentObj = resp[0];
    
                  

                  setTimeout(() => {
                    console.log("goo")
                    this.router.navigateByUrl('shopping-detail/'+message.idItem);
                    
                  },500)

    
                 
                }).catch(err => {
                  console.error(err)
                });
            }).catch(err => {
              console.error(err)
            });
            
          }
      })
      */

    });
  }
}
