import { Component,Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { OfferteService } from '../services/offerte.service';
import { debug } from 'util';
 

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(public storage: Storage, public offerteService: OfferteService) {


  }

  getCategoryImage(cat){
    if(!cat.image){
      var id = cat.categoryId ? cat.categoryId : cat.id;
      var cached = this.getFromCache(id);
      if(cached != null && cached != undefined){
          cat.image = cached;
      }else{
          this.offerteService.getCategoryImage(id).subscribe(res => {
            cat.image = res[0].image;
            this.addInCache(id, cat.image);
          });
      }  
    }
  }
  getFromCache(key){
      var catImgCache = {};
      if(localStorage.catImgCache){
          catImgCache = JSON.parse(localStorage.catImgCache);
      }
      return catImgCache[key];
  }

  addInCache(key, img){
      var catImgCache = {};
      if(localStorage.catImgCache){
          catImgCache = JSON.parse(localStorage.catImgCache);
      }
      catImgCache[key] = img;
      localStorage.catImgCache = JSON.stringify(catImgCache);
  }

}
