import { Component, OnInit , ViewChild} from '@angular/core';
import { AngularFirestore} from '@angular/fire/firestore';

import { ActivatedRoute , Router} from '@angular/router';
import { IonContent ,ModalController,NavParams,NavController, IonSearchbar} from '@ionic/angular';
import { CategoryService  } from '../../services/category.service';
import { OfferteService } from 'src/app/services/offerte.service';
@Component({
  selector: 'app-shopping-search',
  templateUrl: './shopping-search.page.html',
  styleUrls: ['./shopping-search.page.scss'],
})
export class ShoppingSearchPage implements OnInit {

  @ViewChild('searchbar') searchbar: IonSearchbar;
  public resultList: any[];
  public loadedResultList: any[];

  currentPage = 0;
  categoryId = null;

  constructor(
    private firestore: AngularFirestore,
    public offerteService: OfferteService,
    public categoryService: CategoryService,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private navController: NavController,
    public router: Router,
  ) { }


  async close(){
    await this.modalController.dismiss();
  }

  async openDetail(item){
    console.log(item);

    await this.modalController.dismiss();

    this.router.navigateByUrl('/side-menu/shopping/tabs/tab1/shopping-item/'+item.id + '/' + item.name);

    // set timeout
    // setTimeout(function(){
    //   await this.modalController.dismiss();
    // }, 1000);
    //await this.modalController.dismiss();
    //await this.delay(1000);
   
  }


  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


  ngOnInit() {
    let timeoutID = setTimeout(() => {
      this.searchbar.setFocus();
      console.log("setFocus()=======");
      clearTimeout(timeoutID);
    }, 200)


  }


  search(evt){
    const searchTerm = evt.srcElement.value;
    console.log("search value="+searchTerm);
    if(!searchTerm){
      this.resultList = [] ;
      return
    }


    this.offerteService.getSearchCategories(searchTerm).subscribe(res => {

     var  results = res as any[];

      for(var i = 0; i < results.length; i++){
        this.categoryService.getCategoryImage(results[i]);
      }

      this.resultList = results;
      console.log(res);
    });
    
  }
}

