import { Component,Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class OfferteService {

  constructor(private httpClient: HttpClient) {


  }

item: any;
currentObj: any;

//baseUrl = "http://185.247.117.153:3000/";
baseUrl = "https://api.buylert.it/";

getOfferte(start, take, query=null){
  if(!query)
    return this.httpClient.get(this.baseUrl +'getOfferte?start='+ start +'&take=' + take);
  else
    return this.httpClient.get(this.baseUrl +'getOfferte?start='+ start +'&take=' + take + '&query=' +query);
}


getPromozioni(){
    return this.httpClient.get(this.baseUrl +'getPromozioni');
}

getOfferteByCategory(id, start, take, query=null){
  if(!query)
    return this.httpClient.get(this.baseUrl +'getOfferteByCategory?id='+ id + '&start='+ start +'&take=' + take);
  else
    return this.httpClient.get(this.baseUrl +'getOfferteByCategory?id='+ id + '&start='+ start +'&take=' + take + '&query=' + query);
}

getCategories(){
  return this.httpClient.get(this.baseUrl +'getCategories');
}

getSearchCategories(query){
  return this.httpClient.get(this.baseUrl +'searchCategories?query=' + query);
}

getSubCategories(id){
  return this.httpClient.get(this.baseUrl +'getSubCategories?id='+id);
}

getAllSubCategories(id){
  return new Promise((resolve, reject) => { 
    this.httpClient.get(this.baseUrl +'getAllSubCategories?id='+id).subscribe(resp => {
      resolve(resp);
    })   
  })
}

getAncestorCategories(id){
  return this.httpClient.get(this.baseUrl +'getAncestorCategories?id='+id);
}

getSingleCategory(id){
  return this.httpClient.get(this.baseUrl +'getSingleCategory?id='+id);
}

getCategoryImage(id){
  return this.httpClient.get(this.baseUrl +'getCategoryImage?id='+id);
}

getBanners(){
  return this.httpClient.get(this.baseUrl +'getBanners');
}

subscription(action, categoryId){
  console.log(this.baseUrl +'subscription?action=' + action +'&categoryId=' + categoryId + '&token=' + (<any>window).tokenF)
  return this.httpClient.get(this.baseUrl +'subscription?action=' + action +'&categoryId=' + categoryId + '&token=' + (<any>window).tokenF);
}

getItem(id){
  return new Promise((resolve, reject) => { 
    this.httpClient.get(this.baseUrl +'getItem?id=' + id).subscribe(resp => {
      resolve(resp);
    },
    (err) => {
      reject(err)
    });
  })
}

getSingolaOfferta(id){
 return new Promise((resolve, reject) => { 
    this.httpClient.get(this.baseUrl +'getSingolaOfferta?id=' + id).subscribe(resp => {
      resolve(resp);
    },
    (err) => {
      reject(err)
    })   
  })
}




}
